import "@activeadmin/activeadmin";

import "./batch-actions"

import ModalDialog from "./modal-dialog";

function modal_dialog(message, inputs, callback) {
    console.warn("ActiveAdmin.modal_dialog is deprecated in favor of ActiveAdmin.ModalDialog, please update usage.");
    return ModalDialog(message, inputs, callback);
}

export {ModalDialog, modal_dialog};
