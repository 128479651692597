const onDOMReady = function () {
    $(':input[type="number"]').on("paste", function (event) {
        event.preventDefault();

        let paste = (event.originalEvent.clipboardData || window.clipboardData).getData("text");
        paste = paste
            .replaceAll(/[^0-9,.+-]/g, "")
            .replace(/^[,.]+/, "")
            .replace(/[,.+-]+$/, "")
            .replace(",", ".")

        if (isNaN(paste)) {
            $(this).val(0)
        } else {
            $(this).val(paste)
        }
    });
}

$(document).ready(onDOMReady).on('page:load turbolinks:load', onDOMReady);
